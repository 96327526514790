import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Greeting from "../components/greeting"
import Dcf from "../components/images/dcf"
import TungWan from "../components/images/tungwan"

import HotelSoapText from "../svg/hotelsoaptext.svg"
import HotelSoapLogo from "../svg/logo.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="noisey car team & slow mountain club" />
    <div className="full-height" id="top-scroller">
      <div className="image-wrapper">
        <TungWan data-scroll data-scroll-position="top" data-scroll-repeat data-scroll-speed="-2" className="image-fill-area" data-scroll-target="#top-scroller" />
      </div>
      <div data-scroll data-scroll-repeat data-scroll-speed="8" data-scroll-target="#top-scroller" className="max-width-row top-spacing" data-scroll-position="top"><h1><Greeting /></h1></div>
      <div data-scroll data-scroll-repeat className="full-width-row" data-scroll-speed="-4" data-scroll-target="#top-scroller">
        <div className="homepage-big-boy"><HotelSoapText /></div>
      </div>
      <div data-scroll data-scroll-repeat className="fun full-width-row" data-scroll-speed="5" data-scroll-target="#top-scroller">
        <h2>hotel soap</h2>
        <div className="small">noisey car team & slow mountain climbing club<br /><b>buy our super strong dcf bags today!</b></div>
      </div>
      <div className="full-width-row special-stars">
        <div data-scroll data-scroll-repeat data-scroll-speed="-15" data-scroll-target="#top-scroller" ><HotelSoapLogo /></div>
        <div data-scroll data-scroll-repeat data-scroll-speed="5" data-scroll-target="#top-scroller" >*</div>
        <div data-scroll data-scroll-repeat data-scroll-speed="-3" data-scroll-target="#top-scroller" >*</div>
        <div data-scroll data-scroll-repeat data-scroll-speed="3" data-scroll-target="#top-scroller" >*</div>
        <div data-scroll data-scroll-repeat data-scroll-speed="10" data-scroll-target="#top-scroller" >*</div>
      </div>
    </div>
    <div className="max-width-row on-left">
      <div>
        <div className="trippy left" data-scroll data-scroll-repeat data-scroll-speed="3" data-scroll-target="#container">
          <Dcf />
        </div>
        <div className="text-wrap">
          <h2><span>dcf phone sack</span></h2>
          <div className="inner-content">
            <p>Made from DCF our waterproof roll top phone sack can be custom made to fit your smartphone or used to fit oher small goods! It's transparent and you can use your phone through the bag!</p>
          </div>
        </div>
      </div>
    </div>
    <div className="max-width-row on-right">
      <div>
        <div className="trippy left" data-scroll data-scroll-repeat data-scroll-speed="3" data-scroll-target="#container">
          <Dcf />
        </div>
        <div className="text-wrap">
          <h2><span>dcf stuff bags</span></h2>
          <div className="inner-content">
            <p>Custom made stuff sacks with our special draw string closure, made for anything you want, with squared bottoms or flat - pick your size!</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
