import React from "react"

const message = () => {
  var day = new Date();
  var hr = day.getHours();
  if (hr >= 0 && hr < 12) {
    return ("good morning!")
  } else if (hr === 12) {
     return "time for lunch!!" 
  } else if (hr >= 12 && hr <= 17) {
     return "almost done for the day!"
  } else {
     return "goodnight!"
  }
}

const Greeting = () => (
  <span>{message()}</span>
)

export default Greeting
